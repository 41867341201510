<template>
  <div>
    <div class="container">
      <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': '/admin' },
        { 'name': 'Feedback', 'link': '/admin/feedback'},
        { 'name': this.statusText[this.type]}
      ]"/>
      <div class="main-container">
        <h1 class="font-weight-bold">Feedback - {{ this.statusText[this.type] }} </h1>
        <div v-if="loadPage">
          <div class="container" v-if="showNoResults">
            <b-alert v-model="showNoResults" variant="success" dismissible>
              No Feedback found, please try again
            </b-alert>
          </div>
          <div class="container">
            <div class="col-xl-12 content-box">
              <div class="row d-flex justify-content-between px-3 mb-2 pt-4">
                <div class="font-weight-bold d-none d-sm-block">
                  Filter By Date And reason
                </div>
                <div class="w-auto">
                  Total Feedback: <span class="font-weight-bold">{{ totalFeedback }}</span>
                </div>
              </div>
              <b-row class="d-flex justify-content-center pb-2">
                <b-col cols="12" md="6" lg="2">
                  <b-form-datepicker id="from-datepicker" :date-format-options="dateOptions" v-model="fromDate" class="mb-3 mb-lg-0" placeholder="'From' date"></b-form-datepicker>
                </b-col>
                <b-col cols="12" md="6" lg="2">
                  <b-form-datepicker id="to-datepicker" :date-format-options="dateOptions" v-model="toDate" class="mb-3 mb-lg-0" placeholder="'To' date"></b-form-datepicker>
                </b-col>
                <b-col cols="12" md="2" lg="2">
                  <b-form-select v-model="reasonTypeInput" :options="reasonTypeOptions" class="mb-3 mb-md-0"></b-form-select>
                </b-col>
                <b-col cols="12" md="2" lg="2">
                  <b-form-select v-model="includeExtraFeedback" :options="includeExtraFeedbackOptions" class="mb-3 mb-md-0"></b-form-select>
                </b-col>
              </b-row>
              <b-row class="d-flex justify-content-center pb-2 pt-2">
                <b-col cols="12" md="4" lg="4">
                  <b-button variant="danger" class="w-100 mb-2 mb-md-0" @click="clearFilters">Clear Filter</b-button>
                </b-col>
                <b-col cols="12" md="4" lg="4">
                  <b-button variant="primary" class="w-100 mb-2 mb-md-0" @click="getFeedback(1)">Apply Filter</b-button>
                </b-col>
              </b-row>
              <div class="row row-featured row-featured-category" v-if="feedback.length > 0">
                <div class="container mb-3 mt-3">
                  <table class="table table-striped table-bordered add-manage-table table demo footable-loaded footable"
                         data-filter="#filter" data-filter-text-only="true">
                    <thead>
                    <tr>
                      <th>Feedback by</th>
                      <th>Task</th>
                      <th>Task Owner</th>
                      <th>Reason</th>
                      <th>Extra feedback</th>
                      <th>Feedback created</th>
                    </tr>
                    </thead>
                    <tbody class="mb-md-0 mb-3 text-left">
                    <tr v-for="(data, index) in feedback" :key="index" class="cursor">
                      <td>
                        <router-link class="text-dark  " :to="'/admin/users/' + data.feedback_by.hash" target="_blank">
                          {{ data.feedback_by.username }}
                        </router-link>
                      </td>
                      <td>
                        <router-link class="text-dark" :to="'/' + data.gig.user.username + '/' + data.gig.slug" target="_blank">
                          {{ data.gig.title }}
                        </router-link>
                      </td>
                      <td>
                        <router-link class="text-dark  " :to="'/admin/users/' + data.gig.user.hash" target="_blank">
                          {{ data.gig.user.username }}
                        </router-link>
                      </td>
                      <td>{{ (data.reason_type + 1 > reasonTypeOptions.length) ? 'Reason not available' : reasonTypeOptions[data.reason_type  + 1].text }}</td>
                      <td>{{ data.reason ? data.reason : 'NA' }}</td>
                      <td>{{ date(data.date_created) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination :perPage="feedbackPerPage" :currentPage="currentPage" :total="totalFeedback" v-on:paginationUpdate="updatePagination"
                          v-if="totalFeedback > feedbackPerPage" class="mt-3"/>
            </div>
          </div>
        </div>
        <div v-else>
          <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
          <div class="mb-5">Loading Feedback</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import ApiService from '../../../apiService'
import Pagination from '../../../components/Global/Pagination'

export default {
  name: 'AdminFeedback',
  components: {
    Breadcrumb,
    Pagination
  },
  mounted () {
    this.getFeedback()
  },
  data () {
    return {
      type: this.$route.params.type,
      statusText: {
        'payment': 'Left Payment Page'
      },
      currentPage: 1,
      feedback: [],
      loadPage: false,
      showNoResults: false,
      feedbackPerPage: 25,
      totalFeedback: 0,
      dateOptions: {
        'year': 'numeric',
        'month': 'numeric',
        'day': 'numeric'
      },
      fromDate: '',
      toDate: '',
      reasonTypeInput: null,
      reasonTypeOptions: [
        { value: null, text: 'Please select a reason type' },
        { value: 0, text: 'Couldn’t find what I was looking for' },
        { value: 1, text: 'Prices are too high' },
        { value: 2, text: 'I’m still looking around' },
        { value: 3, text: 'I need to gather things for my brief' },
        { value: 4, text: 'I’ll be coming back' },
        { value: 5, text: 'Other' }
      ],
      includeExtraFeedback: null,
      includeExtraFeedbackOptions: [
        { value: null, text: 'All feedback' },
        { value: 1, text: 'Feedback with extra feedback' },
        { value: 0, text: 'Feedback without extra feedback' }
      ]
    }
  },
  methods: {
    date (date) {
      if (date === null) {
        return 'NA'
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    },
    getFeedback (page = null) {
      if (page !== null) {
        this.currentPage = page
      }

      this.loadPage = false
      this.showNoResults = false

      let filter = this.createFilters()

      ApiService.adminGetFeedback(filter).then(response => {
        this.feedback = response.data.data.results
        this.currentPage = response.data.data.pagination.page
        this.feedbackPerPage = response.data.data.pagination.per_page
        this.totalFeedback = response.data.data.pagination.total
        if (this.feedback.length === 0) {
          this.showNoResults = true
        }
        this.loadPage = true
      }).catch(() => {
      })
    },
    createFilters () {
      let data = 'page=' + this.currentPage + '&per_page=' + this.feedbackPerPage

      if (this.type === 'payment') {
        data = data + '&type=' + 'left_payment_page'
      }

      if (this.includeExtraFeedback !== null) {
        data = data + '&left_feedback=' + this.includeExtraFeedback
      }

      if (this.reasonTypeInput !== null) {
        data = data + '&reason_type=' + this.reasonTypeInput
      }

      if (this.fromDate !== '') {
        data = data + '&from_date=' + this.fromDate
      }

      if (this.toDate !== '') {
        data = data + '&to_date=' + this.toDate
      }

      return data
    },
    clearFilters () {
      if (this.includeExtraFeedback !== null || this.reasonTypeInput !== null || this.fromDate !== '' || this.toDate !== '') {
        this.includeExtraFeedback = null
        this.reasonTypeInput = null
        this.fromDate = ''
        this.toDate = ''
        this.getFeedback(1)
      }
    },
    updatePagination (page) {
      this.currentPage = page
      this.getFeedback()
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}
.content-box {
  overflow: unset;
}

#to-datepicker, #from-datepicker {
  padding-right: 0;
}
</style>
