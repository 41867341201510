<template>
  <div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': '/admin' },
        { 'name': 'Feedback' }
      ]"/>
    <div class="main-container">
      <h1 class=" mb-2"><strong>Feedback</strong></h1>
      <div class="row mt-5 justify-content-center">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 ">
          <Cards type="svg" image="fa-comments-dollar" title="Left payment page" :route="'/admin/feedback/payment'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from '../../../components/Admin/AdminCards'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminFeedbackLanding',
  components: {
    Breadcrumb,
    Cards
  }
}
</script>

<style scoped>

</style>
